<!-- 访客新增详情跳转页面-->
<template>
  <el-dialog
    :title="title"
    :visible.sync="visible"
    width="40%"
    :close-on-click-modal="false"
    @close="closeModal"
  >
    <ax-form ref="form" :formBuilder="formBuilder" @change="typeChange">
    </ax-form>
    <span slot="footer" class="dialog-footer">
      <el-button
        type="primary"
        @click="onSubmit"
        style="background-color: #556bff"
        >保 存</el-button
      >
      <el-button @click="visible = false">取 消</el-button>
    </span>
    <!-- <file-upload
      ref="file"
      class="file"
      fileType="SYS_STAFF"
      :taskDefinitionKey="
        this.$route.query.taskDefinitionKey !== undefined
          ? 'PROCESS_SUBMIT'
          : this.$route.query.taskDefinitionKey
      "
    >
    </file-upload> -->
  </el-dialog>
</template>
<script>
const formList = [
  {
    label: "文件类型",
    type: "radio",
    model: "type",
    options: {
      allowClear: true,
      options: [
        { label: "常住人口信息数据表（全部数据）", value: "0" },
        { label: "常住人口信息数据表（筛选后数据）", value: "1" },
      ],
    },
    col: { span: 24 },
    rules: [{ required: true, message: "请选择文件类型" }],
  },
];
import api from "./api";
import fileUpload from "./file-upload/index.vue";
export default {
  components: { fileUpload },
  data() {
    return {
      api,
      title: "导出文件",
      visible: false,
      showExport: false,
      formBuilder: this.$common.initGridFormData(
        formList,
        { layout: "horizontal", labelWidth: 120 },
        { col: { span: 8 } }
      ),
      exportType: "",
      actions: `${process.env.VUE_APP_BASE_URL}/residentArchives/exportXls`,
    };
  },
  watch: {},
  computed: {},
  mounted() {},
  methods: {
    openModal(record = {}) {
      this.visible = true;
    },
    // 取消
    closeModal() {
      this.visible = false;
      this.$emit("refsh");
    },
    typeChange(value, key) {
      if (key === "type") {
        this.exportType = value;
      }
    },
    onSubmit() {
      const values = JSON.parse(JSON.stringify(this.searchForm.formValues));
      console.log(this.searchForm.formValues,'89');
      if (!isEmpty(values.approveSn)) {
        values.approveSn = "*" + values.approveSn + "*";
      }
      if (!isEmpty(values.username)) {
        values.username = "*" + values.username + "*";
      }
      api.exports({
        fileName: "人员信息.xls",
        params: values,
      });
    },
  },
};
</script>

<style lang="less" scoped>
.dialog-footer {
  display: flex;
  justify-content: end;
}

/deep/.el-dialog {
  border-radius: 20px !important;
}

/deep/.ant-descriptions-title {
  font-size: 0.08rem;
  border-left: 4px solid #556bff;
  padding-left: 0.5%;
}

/deep/.ant-descriptions-item-label {
  color: #7d8da6 !important;
}

/deep/.ant-descriptions-item-content {
  color: #2d3138;
}
.upload {
  display: flex;
  flex-direction: column;
}
</style>